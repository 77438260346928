//自定义缩放控件
L.Control.Zoom = L.Control.extend({
    //属性选择设置
    options: {
        position:'bottomleft', //设置缩放控件的位置
        zoomInText: "<span class='zn-zoom-icon icon iconfont icon-jiahao'></span>",    //设置放大按钮的内容提示
        zoomOutText: "<span class='zn-zoom-icon icon iconfont icon-jianhao'></span>",   //设置缩小按钮的内容提示
        zoomInTitle:'放大',
        zoomOutTitle:'缩小'
    },
    onAdd:function(map){
        var zoomName = 'zn-leaflet-control-zoom',
        container = L.DomUtil.create('div',zoomName +' zn-leaflet-bar'),
        options = this.options;
        this._zoomInButton = this._createButton(options.zoomInText,options.zoomInTitle,'zn-zoom-a ' + zoomName+'-in',container,this._zoomIn, this);
        this._zoomOutButton = this._createButton(options.zoomOutText,options.zoomOutTitle,'zn-zoom-a ' + zoomName+'-out',container,this._zoomOut, this);
        return container;
    },
    //放大方法
    _zoomIn: function(e){
        if(!this._disabled){
            this._map.zoomIn(e.shiftKey?3:1);
        }
    },
    //缩小方法
    _zoomOut:function(e){
        if(!this._disabled){
            this._map.zoomOut(e.shiftKey?3:1);
        }
    },
    //创建按钮
    _createButton:function(html, title, className, container, fn, context){
			var link = L.DomUtil.create('a', className, container);
			link.innerHTML = html;
			link.href = '#';
			link.title = title;
	
			var stop = L.DomEvent.stopPropagation;
	
			L.DomEvent
				.on(link, 'click', stop)
				.on(link, 'mousedown', stop)
				.on(link, 'dblclick', stop)
				.on(link, 'click', L.DomEvent.preventDefault)
				.on(link, 'click', fn, context)
				.on(link, 'click', this._refocusOnMap, context);
	
			return link;
    }
});

//重新定义缩放控件
L.control.zoom = function(options){
    return new L.Control.Zoom(options);
};